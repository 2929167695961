import ui.AppVariant

enum class Variant(override val url: String, override val address: String? = null) : AppVariant {
    Default("https://www.jetbrains.com") {
        override fun addLinks(addLink: (href: String, title: String) -> Unit) {
        }
    }
}

suspend fun main() {
    launchApp {
        variant = Variant.Default
    }
}